    import React, { useState, useRef, useEffect } from 'react';
    import moment from "moment";
    import numeral from "numeral";
    import { useDispatch, useSelector } from 'react-redux';
    import { useForm, Controller } from 'react-hook-form';
    import Calendar from '@toast-ui/calendar';
    import '@toast-ui/calendar/dist/toastui-calendar.min.css';
    import { Button } from 'primereact/button';
    import { Dialog } from 'primereact/dialog';
    import { Dropdown } from 'primereact/dropdown';
    import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

    // Interfaces
    import { IAPIOption } from '../../../../../../models/interfaces/IAPIOption';
    import { IConsumeAPI } from '../../../../../../models/interfaces/IConsumeAPI';
    import { IFieldValue } from '../../../../../../models/interfaces/IFieldValue';

    // Models
    import { DialogField } from '../../../../../../models/classes/DialogField';
    import { PanelFieldData } from '../../../../../../models/classes/PanelFieldData';

    // Components
    import FieldGenerator from '../../../../../../utils/field_generator';

    // Redux
    import { consumeAPI } from '../../../../../../redux/actions/panelItem';
    import { State } from '../../../../../../redux/reducers';

    // Utils
    import { isEmpty } from '../../../../../../utils/validation';
    import api from '../../../../../../common/api';

    import { getTodayDate } from '../../../../../../utils/date';
    import { getDropdownValues } from '../../common/action';

    import { ProgressSpinner } from "primereact/progressspinner";
    interface IPanelCalendarActivity {
      widgetName: string;
      panelData: any;
    }

    const PanelCalendarActivity: React.FC<IPanelCalendarActivity> = ({ widgetName, panelData }) => {
      const containerRef = useRef<HTMLDivElement>(null);
      const formRef = useRef(null);
      const dataOriginalRef = useRef<any[]>([]);
      const [calendar, setCalendar] = useState<Calendar | null>(null);
      const [view, setView] = useState<'month' | 'week' | 'day'>(panelData?.ActivityOption?.type || 'month');
      const [showAddDialog, setShowAddDialog] = useState(false);
      const [showViewDialog, setShowViewDialog] = useState(false);
      const [showEditDialog, setShowEditDialog] = useState(false);
      const [selectedEvent, setSelectedEvent] = useState<any>(null);
      const [formData, setFormData] = useState<any>({});
      const [dataOriginal, setDataOriginal] = useState<any>([]);
      // First, update the state management at the top of your component
      const [isAddLoading, setIsAddLoading] = useState(false);
      const [isEditLoading, setIsEditLoading] = useState(false);
      const [isDeleteLoading, setIsDeleteLoading] = useState(false);

      const [dialog, setDialog] = useState<any>(null);
      const [dropdownValues, setDropdownValues] = useState<{[key: string]: any[]}>({});

      const [currentDate, setCurrentDate] = useState(moment());
      const [selectedYear, setSelectedYear] = useState(moment().year());
      const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);


      const dispatch = useDispatch();
      const panelItemSelector = useSelector((state: State) => state.panelItem);
      const panelName = `${widgetName}_${panelData.PanelName}`;

      const { control, handleSubmit, formState: { errors }, reset, setValue, clearErrors } = useForm();

      
      // Generate years (e.g., 10 years before and after current year)
      const years = Array.from({ length: 21 }, (_, i) => ({
        label: String(moment().year() - 10 + i),
        value: moment().year() - 10 + i
      }));

      // Months array
      const months = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
      ];
    

      // Modified navigation handlers
      const handleYearChange = (e: any) => {
        const newYear = e.value;
        setSelectedYear(newYear);
        const newDate = moment(currentDate).year(newYear);
        setCurrentDate(newDate);
        if (calendar) {
          calendar.setDate(newDate.toDate());
          calendar.render();
        }
      };

      const handleMonthChange = (e: any) => {
        const newMonth = e.value;
        setSelectedMonth(newMonth);
        // Subtract 1 when setting moment month since it uses 0-11
        const newDate = moment(currentDate).month(newMonth - 1);
        setCurrentDate(newDate);
        if (calendar) {
          calendar.setDate(newDate.toDate());
          calendar.render();
        }
      };

      // Add new helper functions to check PostActions

      const checkPostActionFlag = (flagName: string): boolean => {
        // Instead of looking for specific ActionName, check all actions for the PostAction flag
        return panelData.Actions.some((action: any) => 
          action.PostActions?.some((postAction: any) => postAction[flagName] === true)
        ) || false;
      };

      const canAddData = checkPostActionFlag('AddRowData');
      const canEditData = checkPostActionFlag('EditRowData');
      const canDeleteData = checkPostActionFlag('DeleteRowData');

      const getActionByPostAction = (postActionFlag: string) => {
        return panelData.Actions.find((action: any) => 
          action.PostActions.some((postAction: any) => postAction[postActionFlag] === true)
        );
      };

      const formatApiEvent = (event: any) => {
        const originalData: any = {};
        const addAction = panelData.Actions.find((action: any) => 
          action.PostActions.some((postAction: any) => postAction.AddRowData === true)
        );
        
        if (addAction) {
          addAction.Fields.forEach((field: PanelFieldData) => {
            originalData[field.FieldName] = event[field.FieldName];
          });
        }

        const eventTitle = (() => {
          if (panelData.Fields) {
            const titleField = panelData.Fields.find((field: PanelFieldData) => field.FieldTitle === true);
            if (titleField) {
              return event[titleField.FieldName] || 'Untitled Event';
            }
          }
          return event.EventName || 'Untitled Event';
        })();
        const startDate = event.StartDate || event.START_DATE;
        const endDate = event.EndDate || event.END_DATE;
        const backgroundColor = event.BackgroundColor || event.BACKGROUND_COLOR;
        
        const uniqueId = event.id || event._id || `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        
        return {
          id: uniqueId,
          calendarId: '1',
          title: eventTitle,
          start: new Date(startDate),
          end: new Date(endDate),
          backgroundColor: backgroundColor,
          category: 'time',
          isAllday: false,
          ...originalData,
          ...event // Include all original fields for reference
        };
      };

      useEffect(() => {
        let calendarInstance: Calendar | null = null;
        
        if (containerRef.current) {
          try {
            calendarInstance = new Calendar(containerRef.current, {
              defaultView: view,
              usageStatistics: false,
              isReadOnly: !canAddData,
              month: {
                dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                startDayOfWeek: 0,
                isAlways6Weeks: false,
              },
              week: {
                dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                hourStart: 0,
                hourEnd: 24,
                eventView: true,
                taskView: false
              },
              useDetailPopup: false
            });
      
            // Only add event listeners if calendar is successfully created
            if (calendarInstance) {
              calendarInstance.on('clickEvent', (eventInfo) => {
                const matchingData = dataOriginalRef.current?.find((item:any) => 
                  item.id === eventInfo.event.id
                );
                if (matchingData) {
                  setSelectedEvent(matchingData);
                  setShowViewDialog(true);
                }
              });
      
              calendarInstance.on('selectDateTime', (dateTimeInfo) => {
                if (calendarInstance) {
                  calendarInstance.clearGridSelections();
                  handleAddEvent(dateTimeInfo);
                }
              });

              calendarInstance.on('beforeUpdateEvent', (eventInfo) => {
                loadCalendarData();
              });
      
              setCalendar(calendarInstance);
            }
          } catch (error) {
            console.error('Error initializing calendar:', error);
          }
        }

        return () => {
          if (calendarInstance) {
            try {
              calendarInstance.destroy();
            } catch (error) {
              console.error('Error destroying calendar:', error);
            }
          }
        };
      }, []);

      useEffect(() => {
        if (calendar) {
          loadCalendarData();
          calendar.on('dateChange', (date) => {
            const newDate = moment(date.date);
            setCurrentDate(newDate);
            setSelectedYear(newDate.year());
            setSelectedMonth(newDate.month() + 1); // Add 1 to convert from 0-11 to 1-12
          });
        }
      }, [calendar]);

      const loadCalendarData = async () => {
        if (!panelData?.LoadData?.APIName || !calendar) return;
        try {
          const requestBody = {
            APIName: panelData.LoadData.APIName,
            inputParameterValues: []
          };
      
          const apiOption: IAPIOption = {
            path: "query_management",
            method: "POST",
            data: requestBody
          };
      
          const response = await api(apiOption);
          
          if (response.success) {
            const events = response.data.map((event: any) => formatApiEvent(event));
            // Update both the ref and state
            dataOriginalRef.current = events;
            setDataOriginal(events);
            
            // Clear existing events and create new ones
            calendar.clear();
            calendar.createEvents(events);
            
            // Make sure we're in the correct view before rendering
            calendar.changeView(view);
            // Force calendar to refresh view
            calendar.render();
          }
        } catch (error) {
          console.error('Error loading calendar data:', error);
        }
      };

      const LoadingSpinner = () => {
        return (
          <div 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              zIndex: 50
            }}
          >
            <ProgressSpinner style={{ width: '50px', height: '50px' }} />
          </div>
        );
      };

      const createDialogField = (field: PanelFieldData, value: any = null) => {
        // The DialogField constructor takes (tableField: PanelFieldData, disabled: boolean, value: any)
        const disabled = field.hasOwnProperty("Editable") ? !field.Editable : false;
        return new DialogField(field, disabled, value);
      };

      const handleAddEvent = (dateTimeInfo: any) => {
        const initialData: any = {};
      
        const addAction = getActionByPostAction('AddRowData');
        
        if (addAction) {
          addAction.Fields.forEach((field: PanelFieldData) => {
            if (field.DataType === "Datetime" || field.DataType === "Date") {
              if (!isEmpty(field?.DefaultValue) || isEmpty(field?.DefaultValue as DialogField)) {
                if (field?.DataType === "Date" && !isEmpty(field.DefaultValue)) {
                    if (field.FieldName === "StartDate" || field.FieldName === "START_DATE" || field.FieldName.toUpperCase() === "STARTDATE") {
                      field.DefaultValue = moment(getTodayDate(field.DefaultValue) || dateTimeInfo.start, "YYYY-MM-DD").format(field.FieldFormat || "YYYY-MM-DD");
                    } else if (field.FieldName === "EndDate" || field.FieldName === "END_DATE" || field.FieldName.toUpperCase() === "ENDDATE") {
                      field.DefaultValue = moment(getTodayDate(field.DefaultValue) || dateTimeInfo.end, "YYYY-MM-DD").format(field.FieldFormat || "YYYY-MM-DD");
                    }
                }

                if (field?.DataType === "Datetime" && !isEmpty(field.DefaultValue)) {
                  if (field.FieldName === "StartDate" || field.FieldName === "START_DATE" || field.FieldName.toUpperCase() === "STARTDATE") {
                    field.DefaultValue = moment(getTodayDate(field.DefaultValue) || dateTimeInfo.start, "YYYY-MM-DD HH:mm:ss").format(field.FieldFormat || "YYYY-MM-DD HH:mm:ss");
                  } else if (field.FieldName === "EndDate" || field.FieldName === "END_DATE" || field.FieldName.toUpperCase() === "ENDDATE") {
                    field.DefaultValue = moment(getTodayDate(field.DefaultValue) || dateTimeInfo.end, "YYYY-MM-DD HH:mm:ss").format(field.FieldFormat || "YYYY-MM-DD HH:mm:ss");
                  }
                }
              }
            } else {
              initialData[field.FieldName] = field.DefaultValue || null;
            }
          });
        }
      
        setFormData(initialData);
        reset(initialData);
        setShowAddDialog(true);
      };

      const handleCloseAddDialog = () => {
        setShowAddDialog(false);
        if (calendar) {
          calendar.clearGridSelections();
        }
        reset({});
      };

      const handleSaveEvent = async (data: any) => {
        if (!calendar) return;
        setIsAddLoading(true);
        const addAction = getActionByPostAction('AddRowData');
        
        if (addAction) {
          const fieldValues: IFieldValue[] = addAction.Fields.map((field: PanelFieldData) => {
            let value = data[field.FieldName];
      
            // Format value based on DataType
            if (!isEmpty(value)) {
              if (field.DataType === "Date") {
                value = moment(value, field.FieldFormat || undefined).format("YYYY-MM-DD");
              }
      
              if (field.DataType === "Datetime") {
                value = moment(value, field.FieldFormat || undefined).format("YYYY-MM-DD HH:mm:ss");
              }
      
              if (field.DataType === "Decimal" || field.DataType === "Number") {
                const parsedValue = numeral(value).value();
                if (parsedValue !== null) {
                  value = parsedValue;
                }
              }
            }
      
            return {
              fieldName: field.FieldName,
              fieldType: field.DataType,
              value,
              dataType: field.DataType,
              isFieldOverrider: false
            };
          });
      
          const options: IConsumeAPI = {
            targetPanel: panelName,
            APIName: addAction.APIName,
            inputParameterValues: fieldValues,
            UseQueue: false,
            isAPIForDataTable: false,
            postActions: addAction.PostActions
          };
      
          try {
            await consumeAPI(dispatch, options, async (response:any) => {
              handleCloseAddDialog();
              setIsAddLoading(false);
              await loadCalendarData();
            });
          } catch (error) {
            console.error('Error saving event:', error);
          }
        }
      };

      const handleEditEvent = () => {
        if (!selectedEvent) return;
        const editAction = getActionByPostAction('EditRowData');
        const editData: any = { ...selectedEvent };

        if (editAction) {
          editAction.Fields.forEach((field: PanelFieldData) => {
            if (field.DataType === "Datetime" || field.DataType === "Date") {
              // Handle StartDate and EndDate specifically
              if (field.FieldName === "StartDate" || field.FieldName === "START_DATE" || field.FieldName.toUpperCase() === "STARTDATE") {
                editData.StartDate = moment(selectedEvent.start).format(field.FieldFormat || "YYYY-MM-DD HH:mm:ss");
              } else if (field.FieldName === "EndDate" || field.FieldName === "END_DATE" || field.FieldName.toUpperCase() === "ENDDATE") {
                editData.EndDate = moment(selectedEvent.end).format(field.FieldFormat || "YYYY-MM-DD HH:mm:ss");
              } else if (!isEmpty(field?.DefaultValue)) {
                if (field?.DataType === "Date") {
                  const todayDate = getTodayDate(field.DefaultValue);
                  field.DefaultValue = moment(todayDate, "YYYY-MM-DD").format(field.FieldFormat);
                }
                if (field?.DataType === "Datetime") {
                  const todayDate = getTodayDate(field.DefaultValue);
                  field.DefaultValue = moment(todayDate, "YYYY-MM-DD HH:mm:ss").format(field.FieldFormat);
                }
              }
            }
          });
        }

        setFormData(editData);
        reset(editData);
        setShowViewDialog(false);
        setShowEditDialog(true);
      };

      const handleUpdateEvent = async (data: any) => {
        if (!calendar || !selectedEvent) return;
        setIsEditLoading(true);
        const editAction = getActionByPostAction('EditRowData');
        if (editAction) {
          const fieldValues: IFieldValue[] = editAction.Fields.map((field: PanelFieldData) => {
            let value = data[field.FieldName];
      
            // Format value based on DataType
            if (!isEmpty(value)) {
              if (field.DataType === "Date") {
                value = moment(value, field.FieldFormat || undefined).format("YYYY-MM-DD");
              }
      
              if (field.DataType === "Datetime") {
                value = moment(value, field.FieldFormat || undefined).format("YYYY-MM-DD HH:mm:ss");
              }
      
              if (field.DataType === "Decimal" || field.DataType === "Number") {
                const parsedValue = numeral(value).value();
                if (parsedValue !== null) {
                  value = parsedValue;
                }
              }
            }
      
            return {
              fieldName: field.FieldName,
              fieldType: field.DataType,
              value,
              dataType: field.DataType,
              isFieldOverrider: false
            };
          });

          const options: IConsumeAPI = {
            targetPanel: panelName,
            APIName: editAction.APIName,
            inputParameterValues: fieldValues,
            UseQueue: false,
            isAPIForDataTable: false,
            postActions: editAction.PostActions
          };

          try {
            await consumeAPI(dispatch, options, async (response:any) => {
              setShowEditDialog(false);
              setIsEditLoading(false);
              await loadCalendarData();
            });
            
          } catch (error) {
            console.error('Error updating event:', error);
          }
        }
      };

      const handleDeleteEvent = () => {
        confirmDialog({
          message: 'Are you sure you want to delete this event?',
          header: 'Delete Confirmation',
          icon: 'pi pi-exclamation-triangle',
          acceptClassName: 'p-button-danger',
          accept: async () => {
            if (!calendar || !selectedEvent) return;
            setIsDeleteLoading(true);
            const deleteAction = getActionByPostAction('DeleteRowData');
            
            if (deleteAction) {
              const primaryKeyField = deleteAction.PostActions
                .find((action:any) => action.DeleteRowData)
                ?.RefreshFieldParameters
                ?.find((param:any) => param.PrimaryKey)
                ?.FieldName;
      
              if (!primaryKeyField) {
                console.error('Primary key field not found in RefreshFieldParameters');
                return;
              }
      
              const fieldValues: IFieldValue[] = [{
                fieldName: primaryKeyField,
                dataType: 'Text', // Mungkin perlu menyesuaikan tipe data sesuai dengan field
                value: selectedEvent[primaryKeyField]
              }];
      
              const options: IConsumeAPI = {
                targetPanel: panelName,
                APIName: deleteAction.APIName,
                inputParameterValues: fieldValues,
                UseQueue: false,
                isAPIForDataTable: false,
                postActions: deleteAction.PostActions
              };
      
              try {
                await consumeAPI(dispatch, options, async (response:any) => {
                  setShowViewDialog(false);
                  setIsDeleteLoading(false);
                  await loadCalendarData();
                });
              } catch (error) {
                console.error('Error deleting event:', error);
              }
            }
          }
        });
      };

      useEffect(() => {
        const fetchDropdownValues = async () => {
          const fields = getActionByPostAction('AddRowData')?.Fields || [];
          const editFields = getActionByPostAction('EditRowData')?.Fields || [];
          const allFields = [...fields, ...editFields];
      
          for (const field of allFields) {
            if (field.ListOfValuesAPI && !dropdownValues[field.FieldName]) {
              try {
                const response: any = await getDropdownValues(field.ListOfValuesAPI, true);
                if (response?.success && response.data) {
                  let ListOfValues:any = [];
                  if (Array.isArray(response.data) && response.data.length > 0) {
                    ListOfValues = response.data;
                  } else if (response.data.ListOfValues) {
                    ListOfValues = response.data.ListOfValues;
                  }
      
                  if (ListOfValues) {
                    setDropdownValues(prev => ({
                      ...prev,
                      [field.FieldName]: ListOfValues
                    }));
                  }
                }
              } catch (error) {
                console.error('Error fetching dropdown values:', error);
              }
            }
          }
        };
      
        fetchDropdownValues();
      }, [showAddDialog, showEditDialog]);

      const renderDialogFields = (fields: PanelFieldData[]) => {
        return fields.map((field: PanelFieldData, index) => {
          // if (field.Hidden) return null;
      
          const dialogField = createDialogField(
            field, 
            formData[field.FieldName] || field.DefaultValue || null
          );
      
          // Set ListOfValues from our cached values
          if (field.ListOfValuesAPI && dropdownValues[field.FieldName]) {
            dialogField.ListOfValues = dropdownValues[field.FieldName];
          }
      
          return (            
              <div
                key={index} 
                style={{
                    height: "100%",
                    whiteSpace: "nowrap",
                    display: field!.Hidden? "none":"flex",
                    flexDirection: field?.InputType === "DisplayText" || field?.InputType === "FileUpload" ? "row" : process.env.REACT_APP_LAYOUT_INPUT === "grid" ? "column" : "row",
                    alignItems: field?.InputType === "FileUpload" ? undefined : "flex-start",
                    justifyContent: "space-between",
                    marginTop:"10px"
                }}
              >
              {/* <div key={field.FieldName} className="flex flex-column gap-2" style={{margin:"5px"}}> */}
                <label style={{marginRight:'20px'}} htmlFor={field.FieldName}>{field.FieldLabel}</label>
                <Controller
                  name={field.FieldName}
                  control={control}
                  defaultValue={dialogField.value}
                  rules={dialogField.Mandatory ? { required: `${field.FieldLabel} is required.` } : undefined}
                  render={({ field: formField, fieldState }) => (
                    <FieldGenerator
                      reference={formField}
                      field={dialogField}
                      ref={formRef}
                      customProps={{
                        chartName: panelName,
                        container: {
                          widgetName,
                          panelName,
                          formRef,
                          form: {
                            setValue: (name: string, value: any) => {
                              formField.onChange(value);
                              setFormData((prev:any) => ({
                                ...prev,
                                [name]: value
                              }));
                            },
                            clearErrors,
                            setError: (name: string, error: any) => {
                              // Handle error setting if needed
                            }
                          },
                        },
                        dialog,
                        setDialog,
                        style: { width: field.FieldWidth || '100%' }
                      }}
                      fieldState={fieldState}
                      onChange={(value:any) => {
                        formField.onChange(value);
                        setFormData((prev:any) => ({
                          ...prev,
                          [field.FieldName]: value
                        }));
                      }}
                    />
                  )}
                />
                {errors[field.FieldName] && (
                  <small className="p-error">{errors[field?.FieldName]?.message}</small>
                )}
              </div>          
          );
        });
      };

      const renderAddDialog = () => (
        <Dialog 
          header="Add New Event" 
          visible={showAddDialog} 
          style={{ width: '450px' }} 
          modal
          onHide={handleCloseAddDialog}
          footer={(
            <div>
              <Button label="Cancel" icon="pi pi-times" onClick={handleCloseAddDialog} className="p-button-text" />
              <Button label="Save" icon="pi pi-check" onClick={handleSubmit(handleSaveEvent)} />
            </div>
          )}
        >
          <div className="flex flex-column gap-4 relative">
          {isAddLoading && <LoadingSpinner />}
            {renderDialogFields(
              getActionByPostAction('AddRowData')?.Fields || []
            )}
          </div>
        </Dialog>
      );

      const renderViewDialog = () => (
        <Dialog
          header="Event Details"
          visible={showViewDialog}
          style={{ width: '450px' }}
          modal
          onHide={() => setShowViewDialog(false)}
          footer={(
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
              {canDeleteData && (
                <Button 
                  label="Delete" 
                  icon="pi pi-trash" 
                  className="p-button-danger" 
                  onClick={handleDeleteEvent}
                />
              )}
              {canEditData && (
                <Button 
                  label="Edit" 
                  icon="pi pi-pencil" 
                  onClick={handleEditEvent}
                />
              )}
              <Button 
                label="Close" 
                icon="pi pi-times" 
                onClick={() => setShowViewDialog(false)} 
                className="p-button-text"
              />
            </div>
          )}
        >
          <div className="relative">
            {isDeleteLoading && <LoadingSpinner />}
            {selectedEvent && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {panelData.Fields
                  .filter((field: PanelFieldData) => !field.Hidden)
                  .map((field: PanelFieldData) => {
                    let displayValue = selectedEvent[field.FieldName] || '-';
                    
                    // Format datetime fields
                    if ((field.DataType === 'Datetime' || field.DataType === 'Date') && selectedEvent[field.FieldName]) {
                      if (field.FieldName === 'StartDate') {
                        displayValue = moment(selectedEvent.start).format(field.FieldFormat || 'MM/DD/YYYY hh:mm A');
                      } else if (field.FieldName === 'EndDate') {
                        displayValue = moment(selectedEvent.end).format(field.FieldFormat || 'MM/DD/YYYY hh:mm A');
                      } else {
                        displayValue = moment(selectedEvent[field.FieldName]).format(field.FieldFormat || 'MM/DD/YYYY hh:mm A');
                      }
                    }
      
                    // Handle color radio button display
                    if (field.InputType === 'ColorRadioButton' && selectedEvent[field.FieldName]) {
                      displayValue = (
                        <div 
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            backgroundColor: selectedEvent[field.FieldName],
                            border: '1px solid #dee2e6'
                          }}
                        />
                      );
                    }
      
                    return (
                      <div 
                        key={field.FieldName} 
                        style={{
                          display: 'flex',
                          padding: '8px 0',
                          borderBottom: '1px solid #dee2e6',
                          minHeight: '40px',
                          alignItems: 'center'
                        }}
                      >
                        <label 
                          style={{
                            fontWeight: 600,
                            color: '#4B5563',
                            minWidth: '140px'
                          }}
                        >
                          {field.FieldLabel}:
                        </label>
                        <div style={{ flex: 1, color: '#111827' }}>
                          {displayValue}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </Dialog>
      );

      const renderEditDialog = () => (
        <Dialog
          header="Edit Event"
          visible={showEditDialog}
          style={{ width: '450px' }} 
          modal
          onHide={() => setShowEditDialog(false)}
          footer={(
            <div>
              <Button label="Cancel" icon="pi pi-times" onClick={() => setShowEditDialog(false)} className="p-button-text" />
              <Button label="Update" icon="pi pi-check" onClick={handleSubmit(handleUpdateEvent)} />
            </div>
          )}
        > 
          <div className="flex flex-column gap-4 relative">
            {isEditLoading && <LoadingSpinner />}
            {renderDialogFields(
              getActionByPostAction('EditRowData')?.Fields || []
            )}
          </div>
        </Dialog>
      );
      
      // Modified calendar navigation helper
      const safeCalendarOperation = (operation: string) => {
        if (!calendar) return;

        try {
          let newDate = moment(currentDate);
          const currentView = view || 'month';

          switch(operation) {
            case 'next':
              switch(currentView) {
                case 'month':
                  if (selectedMonth === 12) {
                    newDate = newDate.add(1, 'month');
                    setSelectedMonth(1);
                    setSelectedYear(newDate.year());
                  } else {
                    newDate = newDate.add(1, 'month');
                    setSelectedMonth(newDate.month() + 1);
                  }
                  break;
                case 'week':
                  newDate = newDate.add(1, 'week');
                  setSelectedMonth(newDate.month() + 1);
                  setSelectedYear(newDate.year());
                  break;
                case 'day':
                  newDate = newDate.add(1, 'day');
                  setSelectedMonth(newDate.month() + 1);
                  setSelectedYear(newDate.year());
                  break;
              }
              break;
            case 'prev':
              switch(currentView) {
                case 'month':
                  if (selectedMonth === 1) {
                    newDate = newDate.subtract(1, 'month');
                    setSelectedMonth(12);
                    setSelectedYear(newDate.year());
                  } else {
                    newDate = newDate.subtract(1, 'month');
                    setSelectedMonth(newDate.month() + 1);
                  }
                  break;
                case 'week':
                  newDate = newDate.subtract(1, 'week');
                  setSelectedMonth(newDate.month() + 1);
                  setSelectedYear(newDate.year());
                  break;
                case 'day':
                  newDate = newDate.subtract(1, 'day');
                  setSelectedMonth(newDate.month() + 1);
                  setSelectedYear(newDate.year());
                  break;
              }
              break;
            case 'today':
              newDate = moment();
              setSelectedMonth(newDate.month() + 1);
              setSelectedYear(newDate.year());
              break;
          }

          setCurrentDate(newDate);
          calendar.setDate(newDate.toDate());
          calendar.render();
        } catch (error) {
          console.error('Calendar operation failed:', error);
        }
      };

      return (
        <div className="h-full mb-">
          <div className="flex justify-between items-center mb-4 gap-2" style={{marginTop:"10px", marginBottom: "10px"}}>
            <div style={{display:"flex", gap: "5px"}}>
              <Button label="Today" onClick={() => safeCalendarOperation('today')} />
              <Button icon="pi pi-chevron-left" onClick={() => safeCalendarOperation('prev')} />
              <Button icon="pi pi-chevron-right" onClick={() => safeCalendarOperation('next')} />
              <Dropdown
                value={selectedMonth}
                options={months}
                onChange={handleMonthChange}
                className="w-40"
              />
              <Dropdown
                value={selectedYear}
                options={years}
                onChange={handleYearChange}
                className="w-24"
              />
              <Dropdown
                value={view}
                options={[
                  { label: 'Month', value: 'month' },
                  { label: 'Week', value: 'week' },
                  { label: 'Day', value: 'day' }
                ]}
                onChange={(e) => {
                  setView(e.value);
                  if (calendar) {
                    // First change the view
                    calendar.changeView(e.value);
                    // Then reload the data
                    calendar.clear();
                    const existingEvents = dataOriginalRef.current;
                    if (existingEvents && existingEvents.length > 0) {
                      calendar.createEvents(existingEvents);
                    }
                    // Finally render
                    calendar.render();
                  }
                }}
                className="w-24"
              />
            </div>
          </div>

          <div 
            ref={containerRef} 
            style={{ height: panelData.PanelHeight || '600px' }}
            className="calendar-container"
          />

          {canAddData && renderAddDialog()}
          {renderViewDialog()}
          {canEditData && renderEditDialog()}
        </div>
      );
    };

    export default PanelCalendarActivity;