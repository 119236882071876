import { IHelpButton } from "../models/classes/PanelData";

function simpleHash(input: string) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
        const char = input.charCodeAt(i);
        hash = (hash << 5) - hash + char; // Bitwise operation for a simple hash
        hash |= 0; // Convert to 32-bit integer
    }
    return hash.toString(16); // Return hash as a hexadecimal string
}

export function OpenHelp(data?: IHelpButton[]) {

    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key && key.startsWith("help-")) {
            sessionStorage.removeItem(key); // Remove keys that start with "help-"
        }
    }
    
    if (data) {
        const overrideWidgetConfig = JSON.stringify(data);
        const hash = simpleHash(new Date().toISOString())
        sessionStorage.setItem('help-' + hash, overrideWidgetConfig);
        const screenRoute = "HelpViewer";
        window.open(`${window.location.origin}/#/${screenRoute}?hash=${hash}`);
    }

}