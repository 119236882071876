/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import api from "../../common/api";
import { isEmpty, isNull } from "../../utils/validation";

import {
    ACTION_CALL_API,
    ACTION_CALL_API_ERROR,
    ACTION_CALL_API_SUCCESS,
    ACTION_SUBMIT_DATA,
    ACTION_SUBMIT_DATA_ERROR,
    ACTION_SUBMIT_DATA_SUCCESS,

    API_GET_TABLE_DATA,
    API_GET_TABLE_DATA_ERROR,
    API_GET_TABLE_DATA_SUCCESS,

    API_RESET_TABLE_DATA,

    CANCEL_DATA_FETCH,

    EXPORT_TABLE_DATA,
    EXPORT_TABLE_DATA_ERROR,
    EXPORT_TABLE_DATA_SUCCESS,
    ON_BATCH_ACTION_BUTTON_CLICKED,
    RESET_FORM
} from '../constants/panelItem';

import { IConsumeAPI } from "../../models/interfaces/IConsumeAPI";
import { IAPIOption } from "../../models/interfaces/IAPIOption";
import { ActionData } from "../../models/classes/ActionData";
import { IPostAction } from "../../models/interfaces/IPostAction";
import _ from "lodash";

const AbortController = window.AbortController;

let controller: any;
let signal: any;
let isFetchAborted: boolean = false;

export const cancelDataFetch = (dispatch: Function, targetPanel: string) => {
    controller?.abort();
    isFetchAborted = true;
    dispatch({
        type: CANCEL_DATA_FETCH,
        payload: {targetPanel}
    });
};

//<editor-fold desc="Export Data Table to CSV">
export const exportDataTrigger = async (dispatch: Function, targetPanel: string, setIsLoadingButton?: any) => {
    await dispatch({
        type: EXPORT_TABLE_DATA,
        payload: {targetPanel}
    });
    setIsLoadingButton(false)
};

export const exportDataSuccess = (dispatch: Function) => {
    dispatch({
        type: EXPORT_TABLE_DATA_SUCCESS,
        payload: {message: "Data has been exported."}
    });
};

export const exportDataError = (dispatch: Function, message: string) => {
    dispatch({
        type: EXPORT_TABLE_DATA_ERROR,
        payload: {message}
    });
};
//</editor-fold>

export const resetTableData = (dispatch: Function, targetPanel: string) => {
    dispatch({
        type: API_RESET_TABLE_DATA,
        payload: {
            targetPanel
        }
    });
};

//<editor-fold desc="Submit Action Data">
export const consumeAPI = (dispatch: Function, options: IConsumeAPI, callback?: any) => {
    let {
        targetPanel,
        meta,
        // targetDialog,
        APIName,
        inputParameterValues,
        UseQueue,
        UseArray,
        isAPIForDataTable,
        isCallApiOnly,
        dialogQueueItemIndexToRemove,
        postActions,
        responseType,
        isRequestFromDialog,
        buttonType
    } = options;

    if (isNull(dialogQueueItemIndexToRemove)) dialogQueueItemIndexToRemove = -1;

    dispatch({
        type: isCallApiOnly ? ACTION_CALL_API : (isAPIForDataTable? API_GET_TABLE_DATA : ACTION_SUBMIT_DATA),
        payload: {
            targetPanel,
            postActions,
            meta,
            isRequestFromDialog
            // targetDialog
        }
    });

    Object.assign(meta || (meta = {}), { isRequestFromDialog });

    if (!isEmpty(APIName)) {
        let requestBody = {
            APIName,
            inputParameterValues,
            UseQueue,
            UseArray
        };

        if (UseArray === true) {
            requestBody.UseQueue = false;
        }

        controller = new AbortController();
        signal = controller.signal;
        isFetchAborted = false;

        const apiOption: IAPIOption = {
            path: "query_management",
            method: "POST",
            data: requestBody,
            signal,
            responseType,
            buttonType
        };

        api(apiOption).then(async (response: any) => {
            if (response.hasOwnProperty("filename")) {
                 // Create a link element
                 const link = document.createElement('a');
                 const data = await response.data as Blob;
                 // Create an object URL for the Blob
                 const url = URL.createObjectURL(data);
                 link.href = url;
 
                 // Set the file name
                 link.download = response.filename; // Change to desired file name and extension
                 
                 // Append the link to the body
                 document.body.appendChild(link);
                 
                 // Programmatically click the link to trigger the download
                 link.click();
                 
                 // Clean up
                 document.body.removeChild(link);
                 URL.revokeObjectURL(url);
            }
            
            function processResponse(response: any, postActionIndex?: number): void {
                const responseData = _.get(response, "response", null);
                const isError = _.get(response, "response.ResponseType", 0) >= 3

                if (response.success) {
                    setTimeout(() => {
                        if (!isError) {
                            dispatch(
                                consumeAPISuccess(
                                    targetPanel,
                                    response.data,
                                    isAPIForDataTable,
                                    dialogQueueItemIndexToRemove,
                                    postActionIndex != null? [postActions![postActionIndex]]:postActions,
                                    responseData,
                                    response,
                                    isCallApiOnly,
                                    meta
                                )
                            );    
                        } else {
                            consumeAPIError(
                                targetPanel,
                                typeof response.message === "string"? JSON.stringify(response.message) : response.message,
                                response.hasOwnProperty("data")? response.data : null,
                                isAPIForDataTable,
                                dialogQueueItemIndexToRemove,
                                postActions,
                                responseData,
                                null,
                                isCallApiOnly,
                                meta
                            )
                        }
                        
                    }, 500);
                    if (callback) {
                        callback({
                            targetPanel,
                            success: response.success,
                            data: response.data,
                            isAPIForDataTable,
                            dialogQueueItemIndexToRemove,
                            postActions: postActionIndex != null? [postActions![postActionIndex]]:postActions,
                            response: responseData,
                            rawResponse: response
                        })
                    }
                } else if (!isFetchAborted && !response.success) {
                    const message = !isEmpty(response) && response.hasOwnProperty("message") ? typeof response.message === "string"? JSON.stringify(response.message) : response.message : "Internal Server Error"
                    
                    dispatch(
                        consumeAPIError(
                            targetPanel,
                            // targetDialog,
                            message,
                            response && response.hasOwnProperty("data")? response.data : null,
                            isAPIForDataTable,
                            dialogQueueItemIndexToRemove,
                            postActions,
                            responseData,
                            null,
                            isCallApiOnly,
                            meta,
                        )
                    );

                    if (callback) {
                        callback({
                            targetPanel,
                            success: response.success,
                            message: message,
                            data: response.hasOwnProperty("data")? response.data : null,
                            isAPIForDataTable,
                            dialogQueueItemIndexToRemove,
                            postActions,
                            response: responseData,
                            rawResponse: response
                        })
                    }
                }
            }

            if (Array.isArray(response) && UseQueue) { // if we got response from batch action request
                let index: number = 0;

                for (const res of response) {
                    if (response.length === postActions?.length) {
                        processResponse(res, index);
                    } else {
                        processResponse(res);
                    }

                    index++;
                }
            } else {
                processResponse(response);
            }
        }).catch(error => {
            if (!isFetchAborted) dispatch(
                consumeAPIError(
                    targetPanel,
                    // targetDialog,
                    {
                        error,
                        message: error?.toString()
                    },
                    null,
                    isAPIForDataTable,
                    dialogQueueItemIndexToRemove,
                    postActions,
                    null,
                    {
                        error,
                        message: error?.toString()
                    },
                    isCallApiOnly,
                    meta
                )
            );

            if (callback) {                
                callback({
                    targetPanel,
                    success: false,
                    data: {},
                    isAPIForDataTable,
                    dialogQueueItemIndexToRemove,
                    postActions: postActions,
                    response: {},
                    rawResponse: {
                        error,
                        message: error?.toString()
                    }
                })
            }
        });
    } else dispatch(
        consumeAPIError(targetPanel,
            "API Name is empty",
            // targetDialog,
            null,
            isAPIForDataTable,
            dialogQueueItemIndexToRemove,
            postActions,
            null,
            null,
            isCallApiOnly,
            meta
        )
    );
};

// FOR CHECKING RESPONSE ON CHECKING DATA BEFORE ACTION
export const consumeCheckData = async (dispatch: Function, options: IConsumeAPI) => {
    let {
        targetPanel,
        // targetDialog,
        APIName,
        inputParameterValues,
        UseQueue,
        isAPIForDataTable,
        dialogQueueItemIndexToRemove,
        postActions,
        ReportName,
        DirectDownload,
    } = options;

    if (isNull(dialogQueueItemIndexToRemove)) dialogQueueItemIndexToRemove = -1;

    dispatch({
        type: isAPIForDataTable? API_GET_TABLE_DATA:ACTION_SUBMIT_DATA,
        payload: {
            targetPanel,
            postActions
            // targetDialog
        }
    });

    if (!isEmpty(APIName)) {
        let requestBody = {
            APIName,
            inputParameterValues,
            UseQueue
        };

        controller = new AbortController();
        signal = controller.signal;
        isFetchAborted = false;

        const apiOption: IAPIOption = {
            path: "query_management",
            method: "POST",
            data: requestBody,
            signal
        };

        return api(apiOption).then((response: any) => {
            function processResponse(response: any, postActionIndex?: number): void {
                
                if (response.success) {
                    // setTimeout(() => {
                        dispatch(
                            consumeAPISuccess(
                                targetPanel,
                                // targetDialog,
                                null,
                                isAPIForDataTable,
                                dialogQueueItemIndexToRemove,
                                postActionIndex != null? [postActions![postActionIndex]]:postActions,
                                null,
                                response,
                                false
                            )
                        );
                    // }, 500);
                }
                if (!isFetchAborted && !response.success) {
                    dispatch(
                        consumeAPIError(
                            targetPanel,
                            // targetDialog,
                            typeof response.message === "string"? JSON.stringify(response.message) : response.message,
                            response.hasOwnProperty("data")? response.data : null,
                            isAPIForDataTable,
                            dialogQueueItemIndexToRemove,
                            postActions,
                            response.hasOwnProperty("response")? response.response : null,
                        )
                    );
                }
            }
            
            if (Array.isArray(response) && UseQueue) { // if we got response from batch action request
                let index: number = 0;
                
                for (const res of response) {
                    if (response.length === postActions?.length) {
                        processResponse(res, index);
                    } else {
                        processResponse(res);
                    }
                    
                    index++;
                }
            } else {
                processResponse(response);
            }
            return response;
        }).catch(error => {
            if (!isFetchAborted) {
                dispatch(
                    consumeAPIError(
                        targetPanel,
                        // targetDialog,
                        JSON.stringify(error),
                        null,
                        isAPIForDataTable,
                        dialogQueueItemIndexToRemove,
                        postActions,
                        null
                    )
                );
            }
        });
    } else if(!isEmpty(ReportName)) {
        let requestBody = {
            ReportName,
            inputParameterValues,
            DirectDownload,
        };

        controller = new AbortController();
        signal = controller.signal;
        isFetchAborted = false;

        const apiOption: IAPIOption = {
            path: "report_management",
            method: "POST",
            data: requestBody,
            signal
        };

        return api(apiOption).then((response: any) => {
            function processResponse(response: any, postActionIndex?: number): void {
                if(DirectDownload) {
                    if (response.data instanceof Promise) {
                        response.data.then((resolvedData: Blob) => {
                            const fileType = resolvedData?.type?.split('/')?.[1];
                            const url = window.URL.createObjectURL(resolvedData);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `Export_${ReportName}_${new Date().getTime()}`.concat(fileType?.includes('pdf') || fileType?.includes('zip')  ? `.${fileType}` : ''));
                            document.body.appendChild(link);
                            link.click();
                            exportDataSuccess(dispatch);
                        }).catch((error:any) => {
                            dispatch(
                                consumeAPIError(
                                    targetPanel,
                                    // targetDialog,
                                    JSON.stringify(error),
                                    null,
                                    undefined,
                                    undefined,
                                    postActions,
                                    null
                                )
                            );
                        });
                    }
                }
                if (response.success) {
                    // setTimeout(() => {
                        dispatch(
                            consumeAPISuccess(
                                targetPanel,
                                // targetDialog,
                                null,
                                isAPIForDataTable,
                                dialogQueueItemIndexToRemove,
                                postActionIndex != null? [postActions![postActionIndex]]:postActions,
                                null,
                                response,
                                false
                            )
                        );
                    // }, 500);
                }
                if (!isFetchAborted && !response.success) {
                    dispatch(
                        consumeAPIError(
                            targetPanel,
                            // targetDialog,
                            typeof response.message === "string"? JSON.stringify(response.message) : response.message,
                            response.hasOwnProperty("data")? response.data : null,
                            isAPIForDataTable,
                            dialogQueueItemIndexToRemove,
                            postActions,
                            response.hasOwnProperty("response")? response.response : null,
                        )
                    );
                }
            }
            
            if (Array.isArray(response)) { // if we got response from batch action request
                let index: number = 0;
                
                for (const res of response) {
                    if (response.length === postActions?.length) {
                        processResponse(res, index);
                    } else {
                        processResponse(res);
                    }
                    
                    index++;
                }
            } else {
                processResponse(response);
            }
            return response;
        }).catch(error => {
            if (!isFetchAborted) {
                dispatch(
                    consumeAPIError(
                        targetPanel,
                        // targetDialog,
                        JSON.stringify(error),
                        null,
                        isAPIForDataTable,
                        dialogQueueItemIndexToRemove,
                        postActions,
                        null
                    )
                );
            }
        });
    } else dispatch(
        consumeAPIError(targetPanel,
            "API Name is empty",
            // targetDialog,
            null,
            isAPIForDataTable,
            dialogQueueItemIndexToRemove,
            postActions,
            null
        )
    );
};

export const consumeAPISuccess = (
    targetPanel: string | string[],
    // targetDialog: string | undefined,
    data: any,
    isAPIForDataTable: boolean | undefined,
    dialogQueueItemIndexToRemove: number | undefined,
    postActions: IPostAction[] | undefined,
    response: any,
    otherData: any,
    isCallApiOnly: boolean | undefined = false,
    meta: any = null
) => {
    return {
        type: isCallApiOnly ? ACTION_CALL_API_SUCCESS : (isAPIForDataTable? API_GET_TABLE_DATA_SUCCESS:ACTION_SUBMIT_DATA_SUCCESS),
        payload: {
            targetPanel,
            // targetDialog,
            data,
            dialogQueueItemIndexToRemove,
            postActions,
            response,
            ...otherData,
            meta
        }
    }
};

export const consumeAPIError = (
    targetPanel: string | string[],
    // targetDialog: string | undefined,
    message: any,
    data: any,
    isAPIForDataTable: boolean | undefined,
    dialogQueueItemIndexToRemove: number | undefined,
    postActions: IPostAction[] | undefined,
    response: any,
    otherData?: any,
    isCallApiOnly: boolean | undefined = false,
    meta: any = null
) => {
    return {
        type: isCallApiOnly ? ACTION_CALL_API_ERROR : (isAPIForDataTable? API_GET_TABLE_DATA_ERROR:ACTION_SUBMIT_DATA_ERROR),
        payload: {
            targetPanel,
            // targetDialog,
            message,
            data,
            dialogQueueItemIndexToRemove,
            postActions,
            response,
            ...otherData,
            meta
        }
    }
};
//</editor-fold>

export const onBatchActionButtonClicked = (dispatch: Function, targetPanel: string, actionData: ActionData) => {
    dispatch({
        type: ON_BATCH_ACTION_BUTTON_CLICKED,
        payload: {
            targetPanel,
            actionData
        }
    });
};

export const consumeGenerateReport = (dispatch: Function, options: IConsumeAPI) => {
    let {
        targetPanel,
        inputParameterValues,
        postActions,
        ReportName,
        DirectDownload,
    } = options;

    dispatch({
        type: ACTION_SUBMIT_DATA,
        payload: {
            targetPanel,
            postActions
        }
    });

    if(!isEmpty(ReportName)) {
        let requestBody = {
            ReportName,
            inputParameterValues,
            DirectDownload,
        };

        controller = new AbortController();
        signal = controller.signal;
        isFetchAborted = false;

        const apiOption: IAPIOption = {
            path: "report_management",
            method: "POST",
            data: requestBody,
            signal,
            responseType: DirectDownload ? "blob" : "json",
        };

        return api(apiOption).then((response: any) => {
            function processResponse(response: any, postActionIndex?: number): void {
                if(DirectDownload) {
                    if (response.data instanceof Promise) {
                        response.data.then((resolvedData: Blob) => {
                            const fileType = resolvedData?.type?.split('/')?.[1];
                            const url = window.URL.createObjectURL(resolvedData);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `Export_${ReportName}_${new Date().getTime()}`.concat(fileType?.includes('pdf') || fileType?.includes('zip')  ? `.${fileType}` : ''));
                            document.body.appendChild(link);
                            link.click();
                            exportDataSuccess(dispatch);
                        }).catch((error:any) => {
                            dispatch(
                                consumeAPIError(
                                    targetPanel,
                                    // targetDialog,
                                    JSON.stringify(error),
                                    null,
                                    undefined,
                                    undefined,
                                    postActions,
                                    null
                                )
                            );
                        });
                    }
                }
                if (response.success) {
                    // setTimeout(() => {
                        dispatch(
                            consumeAPISuccess(
                                targetPanel,
                                // targetDialog,
                                null,
                                undefined,
                                undefined,
                                postActionIndex != null? [postActions![postActionIndex]]:postActions,
                                null,
                                response,
                                false
                            )
                        );
                    // }, 500);
                }
                if (!isFetchAborted && !response.success) {
                    dispatch(
                        consumeAPIError(
                            targetPanel,
                            // targetDialog,
                            typeof response.message === "string"? JSON.stringify(response.message) : response.message,
                            response.hasOwnProperty("data")? response.data : null,
                            undefined,
                            undefined,
                            postActions,
                            response.hasOwnProperty("response")? response.response : null,
                        )
                    );
                }
            }
            
            if (Array.isArray(response)) { // if we got response from batch action request
                let index: number = 0;
                
                for (const res of response) {
                    if (response.length === postActions?.length) {
                        processResponse(res, index);
                    } else {
                        processResponse(res);
                    }
                    
                    index++;
                }
            } else {
                processResponse(response);
            }
            return response;
        }).catch(error => {
            if (!isFetchAborted) {
                dispatch(
                    consumeAPIError(
                        targetPanel,
                        // targetDialog,
                        JSON.stringify(error),
                        null,
                        undefined,
                        undefined,
                        postActions,
                        null
                    )
                );
            }
        });
    } else dispatch(
        consumeAPIError(targetPanel,
            "Report Name is empty",
            // targetDialog,
            null,
            false,
            undefined,
            postActions,
            null
        )
    );
}

export const resetForm = async (dispatch: Function, targetPanel:string) => {
    await dispatch({
        type: RESET_FORM,
        payload: {
            targetPanel,
            isLoadingSubmitActionData: false,
            isSuccessSubmitActionData: false,
            isErrorSubmitActionData: false,
            isLoadingCallApi: false,
            isSuccessCallApi: false,
            isErrorCallApi: false,
            loadingData: null,
            successData: null,
            errorData: null
        }
    });
};
