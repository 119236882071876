/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import "./reach_custom_style.css";

import { ActionData } from "../../../../../../../models/classes/ActionData";
import { PanelFieldData } from "../../../../../../../models/classes/PanelFieldData";

import { isEmpty } from "../../../../../../../utils/validation";

import {
  checkDataAPI,
  submitActionButtonData,
} from "../../../common/action";
import { openDialog } from "../utils/dialog";
import { emitData } from "../../../../../../../redux/actions/beacon";
import { checkPermission } from "../../../../../../../utils/permission";
import { confirmBeforeSubmit } from "../utils/confirmDialog";
import api from "../../../../../../../common/api";
import { IAPIOption } from "../../../../../../../models/interfaces/IAPIOption";
import __t from "../../../../../../../utils/translation";

const TableActionButtonElement = (
  actions: ActionData[],
  actionsColumnWidth: string,
  tableFields: PanelFieldData[],
  tableName: string,
  setDialog: Function,
  dispatch: Function,
  rawData: any,
  setTableData: Function
): JSX.Element | null => {

  const onSelect = (action: ActionData, rowData: any) => {
    const onAccept = () => {
      submitActionButtonData(dispatch, action, tableName, tableFields, rowData, undefined, false, rawData, setTableData);
    };

    const onReject = () => {};

    if (action.OnClickAction === "EmitData") {
      const fields = action.Fields.map((field) => ({
        DataName: action.ActionName,
        DataSource: field.FieldName,
        EmittedValue: rowData[field.FieldName],
      }));

      emitData(dispatch, tableName, fields);
    } else if (action.OnClickAction === "EditRow") {
      emitData(dispatch, tableName, {
        EventName: "EditRow",
        Data: rowData,
      });
    } else {
      const setAction = async () => {
        if (!isEmpty(action.Fields)) {
          openDialog(setDialog, action, tableFields, rowData);
        } else if (action.RequiredConfirmation) {
          confirmBeforeSubmit(action.ConfirmationMessage, onAccept, onReject);
        } else {
          submitActionButtonData(
            dispatch,
            action,
            tableName,
            tableFields,
            rowData,
            undefined,
            false,
            rawData,
            setTableData
          );
        }
      };

      const setActionController = async () => {
        const response = await checkDataAPI(dispatch, action, tableName, tableFields, rowData);

        if (!isEmpty(response)) {
          if (!response.success) {
            console.error("Cannot be executed");
            return;
          }
          setAction();
        }
      };

      if (!isEmpty(action.ActionControllerAPI)) {
        setActionController();
      } else {
        setAction();
      }
    }
  };

  const checkRowCondition = (action: ActionData, rowData: any) => {
    if (!rowData) return false;
    const key = `HPO_ACTION_HIDDEN_${action.ActionName}`;
    const value = rowData[key];
    return value === 1 || value === "1" || value === true || value === "true";
  };

  const renderActionButtons = (rowData?: any, _column?: any): JSX.Element | null => {
    // Determine which actions are hidden
    const hiddenStatus = actions.map(
      (action) => checkPermission(action) || checkRowCondition(action, rowData)
    );
  
    // If all actions are hidden, return null (no action buttons)
    const allHidden = hiddenStatus.every((isHidden) => isHidden);
    if (allHidden) return null;
  
    return actions.length > 1 ? (
      <Menu>
        <MenuButton>
          Actions <span aria-hidden>▾</span>
        </MenuButton>
        <MenuList>
          {actions.map((action, index) => {
            const isHidden = checkPermission(action) || checkRowCondition(action, rowData);
  
            return !isHidden ? (
              <MenuItem
                key={index}
                style={{ margin: "5px 0" }}
                onSelect={() => onSelect(action, rowData)}
              >
                {__t(action, "ActionLabel")}
              </MenuItem>
            ) : null;
          })}
        </MenuList>
      </Menu>
    ) : (
      <>
        {(() => {
          const isHidden = checkPermission(actions[0]) || checkRowCondition(actions[0], rowData);
          return !isHidden ? (
            <span
              style={{
                cursor: "pointer",
                color: "blue",
              }}
              onClick={() => onSelect(actions[0], rowData)}
            >
              {__t(actions[0], "ActionLabel")}
            </span>
          ) : null;
        })()}
      </>
    );
  };  

  if (
    !isEmpty(actions) &&
    actions.filter((action) => !checkPermission(action)).length > 0
  ) {
    return (
      <Column
        reorderable={false}
        frozen
        alignFrozen="right"
        body={renderActionButtons}
        style={{
          width: !isEmpty(actionsColumnWidth) ? actionsColumnWidth : "150px",
          textAlign: "center",
        }}
        field="Actions"
        header="Actions"
      />
    );
  }

  return null;
};

export default TableActionButtonElement;
