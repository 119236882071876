/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import { IHelpButton, PanelData } from './PanelData';
import { PanelFieldData } from './PanelFieldData';

export interface IDisplayPositionObject {
    Column: number;
    Row: number;
    ColumnSpan: number;
    RowSpan: number;
}

export interface ILOVParameter {
    Source: {
        WidgetName: string,
        PanelName: string,
        FieldName: string,
        APIParameters: PanelFieldData[]
    }
}
export class WidgetData {
    Title: string = "";
    WidgetName: string = "";
    WidgetWidth: string = "100%";
    PanelType: string = "Normal";
    PanelListInTabFormat?: string | string[];
    Panels: PanelData[] = [];
    DisplayPosition: string | IDisplayPositionObject = "0,0";
    Toggleable: boolean = false;
    Tooltip: string = "";
    TooltipPosition: ('top' | 'bottom' | 'left' | 'right') = "right"
    HelpCenter?: Partial<IHelpButton[] | undefined> = [];
    WorkspaceName: string = "";
    NumberOfColumns: number = 0;
    NumberOfRows: number = 0;
    GridGap: string = "";
    //Example Data
    //"Locale": {
    //     "en-US": {
    //       "Title": "Help Code"
    //     },
    //     "id-ID": {
    //       "Title": "Kode Bantuan"
    //     }
    //}
    Locale: any = {};
    SubscribeComponent?: Partial<ILOVParameter[]> = [];
}
